<template>
	<div :class="sideOverShow ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="sideOverShow"
			@click="close"
		></div>
		<UpdateAssitanceLevel
			@close="close"
			:selected="skillSelected"
			@refetch="reloadData"
			:is-open="sideOverShow"
		/>
	</div>
	<div class="h-full w-full">
		<div
			class="
				__setup-target-smart
				__section
				shadow-sm
				bg-white
				overflow-hidden
			"
		>
			<div
				class="
					__section__header
					p-6
					border-b
					flex
					items-center
					justify-between
				"
			>
				<div class="font-semibold text-neutral-600">
					Lựa chọn mức độ hỗ trợ
					<div
						class="
							font-normal
							leading-5
							text-sm
							mt-2
							text-neutral-500
						"
					>
						Chọn các mức độ hỗ trợ mà cơ sở muốn sử dụng trong việc
						lập kế hoạch hoặc báo cáo.
					</div>
				</div>
			</div>
			<div class="__section__content p-6">
				<div class="grid grid-cols-1 md:grid-cols-3 gap-8">
					<div
						class="flex items-center justify-start gap-4"
						v-for="(skill, index) in skills"
						:key="index"
					>
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M16.6666 5L7.49992 14.1667L3.33325 10"
								stroke="#2AA797"
								stroke-width="1.66667"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<span class="text-neutral-700">{{ skill.name }}</span>
					</div>
				</div>
			</div>
			<div class="__section__footer p-6 flex justify-end">
				<button
					type="button"
					class="button-p text-center whitespace-nowrap"
					@click="open"
				>
					Lựa chọn lĩnh vực
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';
import UpdateAssitanceLevel from '@/views/setupCenter/features/assistanceLevel/features/UpdateAssitanceLevel.vue';

export default {
	name: 'AssitanceLevel',
	components: {
		UpdateAssitanceLevel,
	},

	data() {
		return {
			skills: [],
			skillSelected: [],
			sideOverShow: false,
		};
	},

	mounted() {
		this.fetchData();
	},

	computed: {
		isAdmin() {
			return this.$store.getters.isAdmin;
		},
	},

	methods: {
		async fetchData() {
			try {
				const {
					data: { data },
				} = await $api.org.fetchActiveAssistanceLevel();
				this.skills = data || [];
			} catch (err) {
				console.log(err);
			}
		},

		open() {
			this.skillSelected = [...this.skills];
			this.sideOverShow = true;
		},

		close() {
			this.sideOverShow = false;
			this.skillSelected = [];
		},

		openAddSkill() {
			this.modalIsOpen = true;
		},

		reloadData() {
			this.fetchData();
		},
	},
};
</script>
