
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Multiselect from '@suadelabs/vue3-multiselect';
import { staff } from '@/views/record/employeeDetail/@types/staff.interface';

@Options({
	name: 'ChangeStatus',
	components: { Multiselect },
})
export default class ChangeStatus extends Vue {
	@Prop() staff!: staff;

	state =
		this.staff.state !== 'active'
			? {
					id: '',
					name: '',
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  }
			: null;

	get states() {
		return [
			{ id: 'active', name: 'Đang học' },
			{
				id: 'graduated',
				name: 'Tốt nghiệp',
			},
			{
				id: 'closed',
				name: 'Tạm dừng',
			},
			{
				id: 'stopped',
				name: 'Xin nghỉ',
			},
		];
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	close() {
		this.$emit('close');
	}

	submit() {
		if (['active'].includes(String(this.state?.id))) {
			this.$emit('active');
		} else {
			this.$emit('block', { state: this.state });
		}
	}
}
