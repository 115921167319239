<template>
	<div>
		<div class="font-semibold text-lg text-neutral-600 mb-4">
			{{ index + 1 }}. {{ data?.skill?.name }} ({{
				(data?.goals ?? data.goals_group_by_skill)?.length
			}})
		</div>
		<div class="shadow-sm sm:rounded-2xl overflow-hidden">
			<div class="__record__table overflow-x-auto scroll-bar">
				<div class="overflow-hidden sm:rounded-t-2xl w-fit min-w-full">
					<table class="min-w-full divide-y text-neutral-200">
						<thead class="bg-neutral-100 h-16">
							<tr>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										w-16
										whitespace-nowrap
									"
									scope="col"
								>
									STT
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
									style="max-width: 220px"
								>
									Lĩnh vực
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
									style="min-width: 160px"
								>
									Nội dung
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									<div
										class="
											flex
											items-center
											gap-1
											justify-between
											cursor-pointer
										"
									>
										chỉnh sửa cuối
									</div>
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									<div
										class="
											flex
											items-center
											gap-1
											justify-between
											cursor-pointer
										"
									>
										Người tạo
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(g, i) in data?.goals ??
								data.goals_group_by_skill"
								:key="g.goal_id || g.id"
								:class="
									i % 2 === 0 ? 'bg-white' : 'bg-neutral-50'
								"
								class="
									text-neutral-700
									hover:bg-orange-50
									cursor-pointer
								"
								@click.stop="
									$router.push(
										`${
											isGroup ? '/group-class' : '/goal'
										}/${studentId}/${g.goal_id || g.id}`
									)
								"
							>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div class="ml-2">
										{{ i + 1 }}
									</div>
								</td>
								<td class="px-6 py-4 text-neutral-700">
									{{ g?.skill?.name }}
								</td>
								<td class="px-6 py-4 text-neutral-700">
									<div class="flex items-center">
										<div
											class="
												mr-2
												hover:text-yellow-700
												hover:underline
											"
										>
											{{ g.goal_name || g.name }}
										</div>
										<TooltipGoalDetail
											:goal-id="g.goal_id || g.id"
											:is-group="isGroup"
											:student-id="studentId.toString()"
										/>
									</div>
									<div
										class="flex gap-2 flex-wrap"
										style="max-width: 360px"
									>
										<div
											v-if="g?.raw_source"
											class="
												mt-1
												py-0.5
												px-2.5
												rounded-full
												bg-neutral-200
												text-neutral-700
												h-fit
												w-fit
												font-semibold
												shadow-input
												whitespace-nowrap
											"
											style="font-size: 10px"
										>
											{{ g?.display_source }}
										</div>
										<div
											v-if="g?.source"
											class="
												mt-1
												py-0.5
												px-2.5
												rounded-full
												bg-neutral-200
												text-neutral-700
												h-fit
												w-fit
												font-semibold
												shadow-input
												whitespace-nowrap
											"
											style="font-size: 10px"
										>
											{{ g?.source }}
										</div>
										<div
											v-else
											class="
												mt-1
												py-0.5
												px-2.5
												rounded-full
												bg-neutral-200
												text-neutral-700
												h-fit
												w-fit
												font-semibold
												shadow-input
												whitespace-nowrap
											"
											style="font-size: 10px"
										>
											{{
												$filters.sourceMap(
													g?.state,
													g.editable
												)
											}}
										</div>
										<template
											v-if="
												g?.assistance_levels &&
												org.enable_goal_assistance_level
											"
											class="flex gap-2"
										>
											<div
												v-for="ass in g?.assistance_levels"
												:key="ass"
											>
												<div
													class="
														mt-1
														py-0.5
														px-2.5
														rounded-full
														bg-pink-100
														whitespace-nowrap
														text-neutral-700
														h-fit
														w-fit
														font-semibold
														shadow-input
													"
													style="font-size: 10px"
												>
													{{ ass }}
												</div>
											</div>
										</template>
									</div>
								</td>
								<td class="px-6 py-4 text-neutral-700">
									<div>
										<div
											v-if="g.updated_by_staff"
											class="flex items-center mb-4"
										>
											<div>
												<img
													v-if="
														g.updated_by_staff
															?.profile_photo
															?.w200
													"
													:src="
														g.updated_by_staff
															?.profile_photo
															?.w200
													"
													alt=""
													class="
														shadow-sm
														inline-block
														h-9
														w-9
														rounded-full
													"
												/>
												<span
													v-else
													:style="{
														background:
															g.updated_by_staff
																?.profile_photo
																?.default
																?.color,
													}"
													class="
														inline-flex
														items-center
														justify-center
														h-9
														w-9
														rounded-full
													"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															g.updated_by_staff
																?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
											</div>
											<div
												class="
													ml-4
													text-blue-600
													font-semibold
													truncate
												"
											>
												{{ g.updated_by_staff.name }}
											</div>
										</div>
										<div v-if="g.last_updated_at">
											{{
												$filters.formatDateTime(
													g.last_updated_at
												)
											}}
										</div>
									</div>
								</td>
								<td class="px-6 py-4 text-neutral-700">
									<div>
										<div
											v-if="g.created_by_staff"
											class="flex items-center mb-4"
										>
											<div>
												<img
													v-if="
														g.created_by_staff
															?.profile_photo
															?.w200
													"
													:src="
														g.created_by_staff
															?.profile_photo
															?.w200
													"
													alt=""
													class="
														shadow-sm
														inline-block
														h-9
														w-9
														rounded-full
													"
												/>
												<span
													v-else
													:style="{
														background:
															g.created_by_staff
																?.profile_photo
																?.default
																?.color,
													}"
													class="
														inline-flex
														items-center
														justify-center
														h-9
														w-9
														rounded-full
													"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															g.created_by_staff
																?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
											</div>
											<div
												class="
													ml-4
													text-blue-600
													font-semibold
													truncate
												"
											>
												{{ g.created_by_staff.name }}
											</div>
										</div>
										<div v-if="g.inserted_at">
											{{
												$filters.formatDateTime(
													g.inserted_at
												)
											}}
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';

export default {
	name: 'GoalListFull',
	components: { TooltipGoalDetail },

	props: {
		index: {
			type: Number,
			required: true,
		},

		isGroup: {
			type: Boolean,
			default: false,
		},

		data: {
			type: Object,
			required: true,
		},

		studentId: {
			type: Number,
			required: true,
		},

		type: {
			type: String,
			default: '',
		},
	},

	computed: {
		org() {
			return this.$store.state.profile.current_org;
		},
	},
};
</script>
