<template>
	<div class="__report-lesson px-12 md-down:px-4 pb-30">
		<div
			class="
				__report-lesson__header
				pt-10
				flex flex-nowrap
				justify-between
				md-down:flex-col md-down:gap-6
			"
		>
			<div
				class="
					__record__title
					text-neutral-500
					font-semibold
					whitespace-nowrap
					flex flex-nowrap
					items-center
					text-4xl
				"
			>
				<svg
					class="mr-3 h-6 w-6"
					fill="none"
					viewBox="0 0 28 28"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M19.3472 7H8.65324C8.65324 7 1.16663 13.6309 1.16663 21.3691C1.16663 25.7888 4.3861 28 6.51361 28H21.4863C23.6228 28 26.8333 25.7886 26.8333 21.3691C26.8333 13.6309 19.3467 7 19.3467 7H19.3472ZM15.0684 22.3615V24.1312H12.932L12.9318 22.3674C11.3572 22.0648 10.368 21.0606 10.2978 19.6025H12.1505C12.2411 20.3678 12.9875 20.861 14.0675 20.861C15.0625 20.861 15.7679 20.3619 15.7679 19.651C15.7679 19.0461 15.3114 18.7011 14.1875 18.4411L12.9963 18.178C11.328 17.818 10.5085 16.9195 10.5085 15.4766C10.5085 14.0337 11.4393 12.9929 12.9202 12.6511V10.8691H15.0566V12.6539C16.5024 12.9866 17.4654 14.006 17.5121 15.3763L15.724 15.3765C15.6333 14.6293 14.96 14.1303 14.0352 14.1303C13.1105 14.1303 12.4431 14.5901 12.4431 15.3101C12.4431 15.894 12.8791 16.2177 13.9532 16.4656L15.0566 16.7077C16.8975 17.1069 17.6905 17.9176 17.6905 19.397C17.6907 20.979 16.7074 22.0379 15.0684 22.3615L15.0684 22.3615Z"
						fill="#65758B"
					/>
					<path
						d="M18.8636 1.16214H16.175C15.6196 0.422379 14.8294 0 14.0001 0C13.1709 0 12.3806 0.422402 11.8252 1.16214H9.13669C8.81509 1.1637 8.51503 1.35595 8.33454 1.67554C8.15405 1.99513 8.11655 2.40126 8.23427 2.76078L9.13667 5.83333H18.8632L19.7657 2.76078C19.8834 2.40126 19.8459 1.99515 19.6654 1.67554C19.4849 1.35593 19.1849 1.16371 18.8632 1.16214H18.8636Z"
						fill="#65758B"
					/>
				</svg>

				Cài đặt học phí
			</div>
			<div class="flex items-center justify-center">
				<button
					v-if="
						$route.name !== _ROUTER_NAME.TUITION_FORMS &&
						$route.query.empty === 'true'
					"
					class="button-p btn--ghost w-fit px-4 mt-4"
					type="button"
					@click="handleCreateTuition"
				>
					Thêm chi phí
				</button>
				<button
					v-if="$route.name !== _ROUTER_NAME.TUITION_SETTING"
					class="button-p btn--ghost w-fit px-4 mt-4"
					type="button"
					@click="goToCreate"
				>
					Tạo biểu mẫu mới
				</button>
			</div>
		</div>
		<div>
			<div class="my-10">
				<div class="border-b border-neutral-200">
					<nav aria-label="Tabs" class="flex space-x-8 tabs">
						<router-link
							v-for="tab in tabs"
							:key="tab.id"
							:to="{
								name: tab.link,
								query: {},
							}"
							class="
								border-transparent
								hover:text-yellow-700 hover:border-yellow-500
								text-neutral-500
								whitespace-nowrap
								py-3
								px-1
								border-b-2
								font-semibold
								cursor-pointer
							"
						>
							<div class="flex items-center gap-2">
								<div>{{ tab.name }}</div>
							</div>
						</router-link>
					</nav>
				</div>
			</div>
		</div>
		<div>
			<router-view :createTuition="createTuition"></router-view>
		</div>
	</div>
</template>

<script>
import { _ROUTER_NAME } from '@/enums';

export default {
	computed: {
		_ROUTER_NAME() {
			return _ROUTER_NAME;
		},
	},
	data() {
		return {
			isLoading: false,
			tabs: [
				{
					id: 0,
					name: 'Chi phí',
					link: _ROUTER_NAME.TUITION_SETTING,
				},
				{
					id: 1,
					name: 'Biểu mẫu',
					link: _ROUTER_NAME.TUITION_FORMS,
				},
			],
			createTuition: 0,
		};
	},

	methods: {
		handleCreateTuition() {
			this.createTuition = this.createTuition + 1;
			setTimeout(() => {
				this.createTuition = 0;
			}, 100);
		},

		goToCreate() {
			window.sessionStorage.removeItem('formPreview');
			this.$router.push({ name: _ROUTER_NAME.TUITION_FORMS_CREATE });
		},
	},
};
</script>
<style lang="scss" scoped>
.tabs {
	.router-link-exact-active {
		@apply text-yellow-700 border-yellow-500;
	}
}
</style>
