<template>
	<div class="pb-30 px-10 md-down:px-4">
		<div
			class="
				__report-lesson__header
				pt-10
				pb-10
				flex flex-nowrap
				justify-between
				items-center
			"
		>
			<div
				class="
					__record__title
					text-neutral-500
					font-semibold
					whitespace-nowrap
					flex flex-nowrap
					items-center
					text-4xl
					cursor-pointer
				"
				@click="$router.back()"
			>
				<svg
					class="mr-2"
					fill="none"
					height="28"
					viewBox="0 0 28 28"
					width="28"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M17.5 7L10.5 14L17.5 21"
						stroke="#94A3B8"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
					/>
				</svg>
				Xem trước
			</div>
			<div class="flex items-center justify-end gap-8">
				<button
					class="
						button-p
						text-center
						bg-none
						shadow-none
						flex
						items-center
						justify-center
						btn--text
						w-fit
					"
					type="button"
				>
					<svg
						class="mr-2"
						fill="none"
						height="16"
						viewBox="0 0 16 16"
						width="16"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
					</svg>

					Xoá
				</button>
				<button
					class="
						button-p
						text-center
						bg-none
						shadow-none
						flex
						items-center
						justify-center
						btn--text
						w-fit
					"
					type="button"
					@click="$router.back()"
				>
					<svg
						class="mr-2"
						fill="none"
						height="16"
						viewBox="0 0 16 16"
						width="16"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M12.4702 6.65087L13.7102 5.41084C13.8461 5.27498 13.914 5.20705 13.9667 5.14509C14.6009 4.39841 14.6009 3.30223 13.9667 2.55555C13.914 2.49359 13.8461 2.42566 13.7102 2.2898C13.5744 2.15394 13.5064 2.08601 13.4445 2.03338C12.6978 1.39915 11.6016 1.39915 10.855 2.03338C10.793 2.086 10.7251 2.15391 10.5893 2.2897L10.5892 2.28978L10.5892 2.2898L9.33099 3.54801C10.0747 4.84221 11.1583 5.91748 12.4702 6.65087ZM7.87633 5.00267L2.86273 10.0163C2.43767 10.4413 2.22514 10.6539 2.0854 10.915C1.94567 11.1761 1.88672 11.4708 1.76883 12.0602L1.37318 14.0385C1.30666 14.3711 1.27339 14.5374 1.368 14.632C1.46261 14.7266 1.62892 14.6934 1.96153 14.6269L3.9398 14.2312L3.93982 14.2312L3.93984 14.2312C4.52927 14.1133 4.82399 14.0544 5.08508 13.9146C5.34617 13.7749 5.55869 13.5624 5.98373 13.1373L5.98376 13.1373L5.98377 13.1373L11.0118 8.10931C9.75111 7.3111 8.68202 6.24922 7.87633 5.00267Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
					</svg>
					Chỉnh sửa tiếp
				</button>
			</div>
		</div>
		<div>
			<div class="bg-white rounded-xl border p-6">
				<div class="flex items-center gap-4">
					<img
						:src="currentOrg.profile_photo.w200"
						alt="Tuition template"
						class="h-16 rounded-lg"
					/>
					<div>
						<div class="font-semibold">{{ currentOrg.name }}</div>
						<div class="text-sm text-neutral-500">
							{{ currentOrg.address }}
						</div>
					</div>
				</div>
				<div class="flex items-center justify-center flex-col">
					<div class="font-semibold text-2xl">{{ data.title }}</div>
					<div class="text-sm italic text-neutral-500">
						Ngày - tháng - năm -
					</div>
				</div>
				<div class="mt-8">
					<div class="text-sm text-neutral-700">Học sinh: -</div>
					<div class="text-sm text-neutral-700 mt-4">
						Lớp bán trú: -
					</div>
					<div class="text-sm text-neutral-700 mt-4">Lớp nhóm: -</div>
				</div>
				<div class="mt-8">
					<div v-for="form in data.forms" :key="form.id">
						<div class="w-fit min-w-full">
							<table class="min-w-full text-neutral-200">
								<thead class="h-12">
									<tr>
										<th
											class="
												px-3
												py-3
												text-center text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												w-16
												whitespace-nowrap
												border
											"
											scope="col"
										>
											STT
										</th>
										<th
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
												border
											"
											scope="col"
										>
											Khoản thu
										</th>
										<th
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
												border
											"
											scope="col"
										>
											Chi phí (VNĐ)
										</th>
										<th
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
												border
											"
											scope="col"
										>
											Số lượng
										</th>
										<th
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
												border
											"
											scope="col"
										>
											Thành tiền (VNĐ)
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in form.cards"
										:key="item.id"
										class="text-neutral-700"
									>
										<td class="border">
											<div
												class="
													px-3
													py-4
													text-center text-neutral-700
												"
											>
												{{ index + 1 }}
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												text-neutral-700
												cursor-pointer
												border
											"
											style="max-width: 200px"
										>
											<div>
												{{ item?.title }}
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
												border
											"
										>
											<div>
												{{
													$filters.priceFormat(
														item.canDelete
															? item.fields[1]
																	.value
															: item.fields[0]
																	.value
													)
												}}
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
												border
											"
										>
											-
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
												border
											"
										>
											-
										</td>
									</tr>
								</tbody>
							</table>
							<div
								class="
									capitalize
									h-12
									flex
									items-center
									justify-between
									bg-neutral-100
									border-l border-r
									px-4
									font-semibold
									text-neutral-500
								"
							>
								<div>Tổng {{ form.title }}</div>
								<div>-</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="
						capitalize
						h-12
						flex
						items-center
						justify-between
						border
						px-4
						font-semibold
						text-lg text-neutral-500
					"
				>
					<div>Tổng chi phí phụ huynh phải đóng</div>
					<div>-</div>
				</div>
				<div class="flex align-center justify-end mt-8">
					<div
						class="w-fit flex align-center justify-center flex-col"
					>
						<div class="text-sm text-neutral-700 text-center">
							Người gửi thông báo
						</div>
						<div class="text-sm text-neutral-700 text-center">
							-
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FormPreview',

	data() {
		return {
			data: {
				title: '',
				name: '',
				forms: [
					{
						id: 1,
						title: 'Khoản thu',
						cards: [
							{
								id: 1,
								title: 'Học can thiệp cá nhân',
								disabled: false,
								fields: [
									{
										id: 1,
										type: 'number',
										placeholder: 'Nhập chi phí',
										label: 'Chi phí thu',
										value: '',
										disabled: true,
									},
									{
										id: 2,
										type: 'text',
										placeholder:
											'Hệ thống đếm số giờ học của học sinh',
										label: 'Số lượng',
										value: '',
										disabled: true,
									},
									{
										id: 3,
										name: 'personal_fee_3',
										type: 'text',
										placeholder:
											'Hệ thống tính số tiền phải đóng',
										label: 'Thành tiền',
										value: '',
										disabled: true,
									},
								],
							},
							{
								id: 2,
								title: 'Học can thiệp lớp nhóm',
								disabled: false,
								fields: [
									{
										id: 1,
										type: 'number',
										placeholder: 'Nhập chi phí',
										label: 'Chi phí thu',
										value: '',
										disabled: true,
									},
									{
										id: 2,
										type: 'text',
										placeholder:
											'Hệ thống đếm số giờ học của học sinh',
										label: 'Số lượng',
										value: '',
										disabled: true,
									},
									{
										id: 3,
										type: 'text',
										placeholder:
											'Hệ thống tính số tiền phải đóng',
										label: 'Thành tiền',
										value: '',
										disabled: true,
									},
								],
							},
							{
								id: 3,
								title: 'Học bán trú',
								disabled: false,
								fields: [
									{
										id: 1,
										type: 'number',
										placeholder: 'Nhập chi phí',
										label: 'Chi phí thu',
										value: '',
										disabled: true,
									},
									{
										id: 2,
										type: 'text',
										placeholder:
											'Hệ thống đếm số giờ học của học sinh',
										label: 'Số lượng',
										value: '',
										disabled: true,
									},
									{
										id: 3,
										type: 'text',
										placeholder:
											'Hệ thống tính số tiền phải đóng',
										label: 'Thành tiền',
										value: '',
										disabled: true,
									},
								],
							},
							{
								id: 4,
								title: 'Phí sử dụng sổ nhật ký điện tử',
								disabled: false,
								fields: [
									{
										id: 1,
										type: 'number',
										placeholder: 'Nhập chi phí',
										label: 'Chi phí thu',
										value: '',
										disabled: true,
									},
									{
										id: 2,
										type: 'text',
										placeholder:
											'Hệ thống đếm số giờ học của học sinh',
										label: 'Số lượng',
										value: '',
										disabled: true,
									},
									{
										id: 3,
										type: 'text',
										placeholder:
											'Hệ thống tính số tiền phải đóng',
										label: 'Thành tiền',
										value: '',
										disabled: true,
									},
								],
							},
						],
					},
					{
						id: 2,
						title: 'Hoàn phí',
						cards: [
							{
								id: 1,
								title: 'Học can thiệp cá nhân',
								disabled: false,
								fields: [
									{
										id: 1,
										type: 'number',
										placeholder: 'Nhập chi phí',
										label: 'Chi phí thu',
										value: '',
										disabled: true,
									},
									{
										id: 2,
										type: 'text',
										placeholder:
											'Hệ thống đếm số giờ học của học sinh',
										label: 'Số lượng',
										value: '',
										disabled: true,
									},
									{
										id: 3,
										name: 'personal_fee_3',
										type: 'text',
										placeholder:
											'Hệ thống tính số tiền phải đóng',
										label: 'Thành tiền',
										value: '',
										disabled: true,
									},
								],
							},
							{
								id: 2,
								title: 'Học can thiệp lớp nhóm',
								disabled: false,
								fields: [
									{
										id: 1,
										type: 'number',
										placeholder: 'Nhập chi phí',
										label: 'Chi phí thu',
										value: '',
										disabled: true,
									},
									{
										id: 2,
										type: 'text',
										placeholder:
											'Hệ thống đếm số giờ học của học sinh',
										label: 'Số lượng',
										value: '',
										disabled: true,
									},
									{
										id: 3,
										type: 'text',
										placeholder:
											'Hệ thống tính số tiền phải đóng',
										label: 'Thành tiền',
										value: '',
										disabled: true,
									},
								],
							},
							{
								id: 3,
								title: 'Học bán trú',
								disabled: false,
								fields: [
									{
										id: 1,
										type: 'number',
										placeholder: 'Nhập chi phí',
										label: 'Chi phí thu',
										value: '',
										disabled: true,
									},
									{
										id: 2,
										type: 'text',
										placeholder:
											'Hệ thống đếm số giờ học của học sinh',
										label: 'Số lượng',
										value: '',
										disabled: true,
									},
									{
										id: 3,
										type: 'text',
										placeholder:
											'Hệ thống tính số tiền phải đóng',
										label: 'Thành tiền',
										value: '',
										disabled: true,
									},
								],
							},
						],
					},
				],
			},
		};
	},

	created() {
		const formPreview = window.sessionStorage.getItem('formPreview');
		if (formPreview) {
			this.data = JSON.parse(formPreview);
		}
	},

	computed: {
		currentOrg() {
			return this.$store.state.profile.current_org;
		},
	},
};
</script>
