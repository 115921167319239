
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import Rating from '@/components/elements/rating/Rating.vue';
import { Options, Vue } from 'vue-class-component';
import SideOver from '@/components/elements/sideOver/SideOver.vue';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading.vue';
import { $api } from '@/services';
import SideOverFilter from '@/components/modules/notifications/SideOverFilter.vue';
import SlideOverFeeNotices from '@/views/feeNotifications/SlideOverFeeNotices.vue';
import Loading from '@/components/common/Loading.vue';
import CreateNotify from '@/views/forms/CreateNotify.vue';
import { _MODAL_TYPE } from '@/enums';

@Options({
	name: 'FeeNotices',
	computed: {
		_MODAL_TYPE() {
			return _MODAL_TYPE;
		},
	},
	components: {
		CreateNotify,
		Loading,
		SlideOverFeeNotices,
		SideOverFilter,
		SideOver,
		Rating,
		DaIcon,
		DaInfinityLoading,
	},
})
export default class ReportWeek extends Vue {
	notificationList: any = [];
	stopLoad = false;
	isInfinityLoading = false;
	sideOverShow = false;
	filter = {};
	isLoading = true;
	type = '';

	//----------------------- 🤍 Created 🤍 -----------------------//
	created() {
		this.getNotificationList();
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	async getNotificationList(query?: any) {
		try {
			const {
				data: { data },
			} = await $api.notification.getFeeNotificationsByYear(query);
			this.notificationList = data || [];
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	clean(obj: any) {
		for (let propName in obj) {
			if (
				obj[propName] === null ||
				obj[propName] === undefined ||
				obj[propName].length <= 0
			) {
				delete obj[propName];
			}
		}
		return obj;
	}

	filterSubmit(queryPa: any) {
		if (queryPa) {
			this.clean(queryPa);
			this.getNotificationList({
				...queryPa,
			});
			this.filter = {
				...queryPa,
			};
		} else {
			this.getNotificationList();
			this.filter = {
				className: '',
				state: '',
				day_care_class_id: '',
			};
		}
	}

	beforeDestroy() {
		this.isInfinityLoading = false;
	}

	closeSideOver() {
		this.sideOverShow = false;
		this.type = '';
	}

	openSideOver(type: string) {
		this.sideOverShow = true;
		this.type = type;
	}
}
